import React, { Component } from 'react';
import Facebook from './facebook.svg'
import Insta from './instagram.svg'
import Whats from './whatsapp.svg'
import Logo from '../img/logo.png'
import {Link} from "react-router-dom";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };


  }



  componentDidUpdate(propsPrecedenti) {
  // Utilizzo tipico (non dimenticarti di comparare le props):

  }

  componentDidMount() {

  }







render() {
    return (
      <div style={{width:'100%',maxWidth:1100,marginLeft:'auto',marginRight:'auto'}}>
        <div style={{padding:20,color:'white',fontSize:14}}>
        <Link style={{color:'white',float:'right'}} to='/privacy'>Privacy</Link>
        <div style={{borderRadius:10,marginTop:20,marginBottom:20,background:'white',display:'inline-block',padding:10}} className="ccl-9aab795066526b4d">
          <a href='https://www.facebook.com/tommyvitello' target="_blank"><img className='iconSocial' alt='social' src={Facebook} /></a>
          <a href='https://www.instagram.com/tommyvitello/' target="_blank"><img className='iconSocial' alt='social' src={Insta} /></a>
        </div>
        <div className='contactF'>
        <div className='cinqu' >
          <h4 style={{color:'white'}}>Contatti</h4>
          <div style={{color:'white'}}> 
          <p>tel 3319717958 - 3474279893</p>
          <a style={{color:'white'}} href="mailto:tommyvitello.2015@gmail.com">tommyvitello.2015@gmail.com</a>
          </div>
        </div>
        <div className='cinqu'>
          <h4 style={{color:'white'}}>Orari ristorante</h4>
          <div style={{color:'white'}}> 
          <p>Lunedì  chiuso</p>
          <p>Martedì/Giovedì 12/14:30 - 18:30/22</p>
          <p>Venerdì/Domenica 12/14:30 - 18:30/23</p>
          <p>Sabato 18:30/24</p>
          </div>
        </div>
        </div>
        </div>
        
        
        <div style={{textAlign:'center',padding:20}}><a href='https://rivolta.app'><img style={{width:200,marginTop:30}} src={Logo} /></a></div>
      </div>



    )
  }
}

//const condition = authUser => !!authUser;

export default Footer
