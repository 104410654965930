import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import Footer from '../Footer';
import Logo from '../img/logoRis.jpeg';
import Header from '../Header';
import {TotalP,stampto,statusConvert} from "../../util/functions.js"
import { Modal } from 'react-responsive-modal';
import Cart from '../Cart';

var idP = process.env.REACT_APP_RESTORANT_TOKEN;

function decimal(num) {
  var n = num.toFixed(2);
  return n
}



class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
				cart:[],
				carts:null,
        auth:null,
        item:null,
        lontano:false,
        categorie:[],
        struttura:[],
        visible : false,
				delyveryForm:false,
        selected:null,
        type:null,
        indirizzoDelivery:null,
        indirizzoDeliverySelect:null,
				reset:false,
				storico:[],
        open:false,
        orineSelect:null,
    };


  }

  openModalAsk(){
      this.setState({
          visibleAsk : true
      });
  }
  openModal(info) {
        this.setState({
            visible : true,
            selected: info
        });
    }

    closeModal() {
        this.setState({
            visible : false,
            selected:null
        });
    }

    closeModalAsk(){
        this.setState({
            visibleAsk : false
        });
    }

  createCardSchema(items){
    let {categorie} = this.state;
    let struttura = [];
    categorie.forEach((categoriaA, i) => {
        let categoria = {
          id:categoriaA._id,
          nome:categoriaA.nome,
          piatti:[],
        }
        items.forEach((piatto, i) => {
          let index = piatto.categorie.indexOf(categoriaA._id)
          if(index >-1){
            categoria.piatti.push(piatto);
          }
        });
        if(categoria.piatti.length > 0){
          struttura.push(categoria)
        }

    });
    this.setState({struttura})

  }

	Total(cart){
		let subTotal = 0;
		cart.items.forEach((x,i)=>{
			subTotal = subTotal + (x.qty * x.prezzo);
		})
		return subTotal
	}

	GetcartS(id){
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.cart
    .getOne({id:id},ppp)
    .then(res=>{

      this.setState({carts:res.data});
    });
  }

  CreatecartS(){
		const cookies = new Cookies();
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};

		var obj = {}
		var oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    ApiTags.endpoints.cart
    .create(obj,ppp)
    .then(res=>{

      this.setState({carts:res.data});
			cookies.set('roo_cagui', res.data._id, { path: '/' , expires:oneYearFromNow});
    });
  }


  getRist(){
    //usa per il get
    const ApiTags= new API ({url:'/ristorante'});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.ristorante
    .getOne({id:idP},ppp)
    .then(res=>{
      this.setState({item:res.data});
      this.getCatgorie(res.data);
    });
  }

  getCatgorie(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyrist'});
    var entity= {name:"categoriabyrist"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.categoriabyrist
    .getOne({id:x._id},ppp)
    .then(res=>{
      this.setState({categorie:res.data})
      this.getPiatti()
    });
  }




  getPiatti(){

    const ApiTags= new API ({url:'/findbyrist'});
    var entity= {name:"findbyrist"};
    ApiTags.createEntity(entity);
      var ppp = {};
      ApiTags.endpoints.findbyrist
      .getOne({id:idP},ppp)
      .then(res=>{

          this.createCardSchema(res.data)
        });
  }

	getOrdiniRist(id){
		const ApiTags= new API ({url:'/oridifattiperrist'});
		var entity= {name:"oridifattiperrist"};
		ApiTags.createEntity(entity);
			var ppp = {};
			ApiTags.endpoints.oridifattiperrist
			.getAllMulty({id:id,idr:idP},ppp)
			.then(res=>{

					this.setState({storico:res.data})

				});
	}

  componentDidMount() {
		const cookies = new Cookies();
		if(cookies.get('roo_cagui')){
			this.GetcartS(cookies.get('roo_cagui'))
		}else {
			this.CreatecartS()

		}


    let newThis = this;
    this.props.firebase.onAuthUserSpecial(function(user) {

        if(user){

          let auth = user;



					newThis.getOrdiniRist(auth.id)
          newThis.setState({auth:auth})
        }
    })


  }




subTotal(value){
  let {carts ,item} = this.state;
	let subTotal= 0;
  if(value){
    value.items.forEach((x,i)=>{

      if(x.qty && x.qty > 0){
        subTotal = subTotal + (x.qty * x.prezzo);
      }
    })
    if(value && value.type != 'take_away'){
      subTotal = subTotal +Number(item ? item.prezzoConsegna : 0)
    }
  }else {
    if(carts){
  		carts.items.forEach((x,i)=>{

  			if(x.qty && x.qty > 0){
          subTotal = subTotal + (x.qty * x.prezzo);
        }
  		})
      if(carts && carts.type != 'take_away'){
        subTotal = subTotal +Number(item ? item.prezzoConsegna : 0)
      }
  	}
  }



	return decimal(subTotal)
}


logOut(){

	this.props.firebase
		.doSignOut();
};


orderType(type){
  switch (type) {
    case 'take_away':
      return 'Da asporto';
    case 'deliveri':
      return 'Consegna a casa';
    default:
      return 'Consegna a casa';
  }
}


cambia(type,value){
	let {carts} = this.state;
	carts[type] = value;
	this.setState({carts})
}

getStatusSync(Ordine){
  let self = this;
  this.props.firebase.ordine(Ordine._id).on('value', function(snapshot) {
    console.log(snapshot.val());
    Ordine.status = statusConvert(snapshot.val().status)
    self.setState({open:true,orineSelect:Ordine})
  })
}

render() {
    let {auth,item,carts} = this.state;
    return (
      <div>
        <Header auth={auth} item={item}  carts={carts} />
        <div className=''>
          <div className=''></div>

          <div className='container restaurant' >
            <div style={{background:'white',maxWidth:1100,marginLeft:'auto',marginRight:'auto',padding:20}}>
            <h2>TUTELA DELLA PRIVACY E SICUREZZA DEI DATI</h2>

            <p>La tutela della privacy e la sicurezza dei dati sono per noi un argomento estremamente importante, per questo motivo ci impegniamo a rispettare e attuare tutte le direttive europee attualmente vigenti. La presente Privacy Policy ha lo scopo di descrivere le modalità di gestione di questo sito, in riferimento al trattamento dei dati personali degli utenti/visitatori che lo consultano. Si tratta di un’informativa che è resa anche ai sensi dell’art. 13 del Reg. UE 679/2016 – Regolamento Europeo in materia di protezione dei dati personali – a coloro che si collegano al sito web e usufruiscono dei relativi servizi web a partire dall’indirizzo https://tommyvitello.it L’informativa è resa soltanto per il sito sopra menzionato e non anche per altri siti web eventualmente consultati dall’utente tramite appositi link. Il sito https://tommyvitello.it è di proprietà di  Luca Buonomo con sede in Milano 20144, Via Casale 6, Partita IVA 10579750968, ed è in licenza d’uso alla società Pantarei sas di Verdicchio Moreno & C. , P. IVA 03437630126 , Sede legale Viale Luigi Pirandello, 4 Busto Arsizio (VA) , che è il Titolare del Trattamento dei dati personali (di seguito “Titolare”) che garantisce il rispetto della normativa sopracitata, improntando il trattamento ai principi di correttezza, liceità, trasparenza e tutela della riservatezza e dei diritti fondamentali degli utenti/visitatori.</p>

            <strong>Quali dati vengono trattati?</strong>

            <p>Dati di navigazione ovvero dati che vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni e associazioni con dati detenuti da terzi, permettere di identificare gli utenti (ad es. gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito).</p>

            <strong>Quali dati vengono trattati e salvati nei vostri Database e con quali finalità?</strong>

            <p>https://tommyvitello.it salva solo ed esclusivamente i dati da te forniti e cioè quelli indispensabili per la registrazione del tuo account, quelli che inserisci per effettuare un ordine senza registrazione o il tuo indirizzo e-mail se desideri iscriverti al nostro servizio di newsletter. I dati relativi alle vostre carte di credito sono trattati esclusivamente da istituti bancari, su server sicuro. https://tommyvitello.it non registra nei propri archivi nessun dato relativo alle vostre carte di credito.</p>

            <strong>Perché vengono salvati questi dati?</strong>

            <p>I dati del tuo account vengono salvati per consentirti di accedere rapidamente alla tua anagrafica e rendere le operazioni di acquisto più veloci. Tramite il tuo account hai infatti la possibilità di consultare lo storico degli ordini o gestire differenti indirizzi di spedizione e puoi modificare questi dati in ogni momento in completa autonomia. Per quanto riguarda gli ordini senza registrazione, vengono salvati esclusivamente i dati necessari a gestire in totale sicurezza il processo di evasione dell'ordine.</p>

            <strong>Qual è la base giuridica del trattamento?</strong>

            <p>Il Titolare applica quale base giuridica del trattamento la necessità di trattare i dati personali ai fini dell’esecuzione di una specifica richiesta effettuata dall’utente/visitatore. Nel caso di finalità commerciali il trattamento si base sull’espresso consenso.</p>

            <strong>Per quanto tempo sono conservati i dati raccolti?</strong>

            <p>I dati sui contatti web non persistono per più di sette giorni mentre i dati forniti volontariamente dagli utenti/visitatori saranno trattenuti sino al soddisfacimento della specifica richiesta effettuata dall’utente/visitatore e/o fino ad esplicita richiesta di cancellazione (revoca del consenso).</p>


            <strong>Sono obbligato a fornire tutti i dati richiesti?</strong>

            <p>A parte quanto specificato per i dati di navigazione, gli utenti/visitatori sono liberi di fornire i propri dati personali. Il loro mancato conferimento può comportare unicamente l’impossibilità di ottenere quanto richiesto.</p>
            <p>Il conferimento dei dati ed il consenso al trattamento per le finalità di comunicazioni commerciali è facoltativo e la mancata iscrizione al nostro servizio di newsletter non impedirà la possibilità di fare acquisti attraverso il nostro sito.</p>


            <strong>Questi dati vengono trasferiti o divulgati a terzi?</strong>

            <p>No, nessun soggetto esterno a https://tommyvitello.it ha accesso ai tuoi dati. Non trasferiamo i tuoi dati a terzi, né tantomeno i tuoi dati saranno venduti o scambiati. https://tommyvitello.it è l'unico ad utilizzare i dati che fornisci e lo fa in sicurezza e nel completo rispetto della privacy. Nel caso di pagamento tramite piattaforma PayPal, il tuo indirizzo di spedizione viene comunicato a PayPal, così da rispondere ai criteri di sicurezza richiesti da questa piattaforma.</p>

            <strong>Il sistema di gestione dei dati è sicuro?</strong>
            <p>Certo, tutti i dati sono salvati su server che rispondono ai più alti standard di sicurezza. https://tommyvitello.it si impegna costantemente a proteggerli e mantenerli sicuri e non ne consente l'accesso a persone esterne all'azienda o non autorizzate.</p>

            <strong>Il vostro sito utilizza i cookies? Di cosa si tratta?</strong>
            <p>Si, https://tommyvitello.it fa uso di cookies. I cookies sono file di testo che contengono informazioni relative alla tua navigazione su un sito e vengono salvati sul disco rigido del computer da cui stai navigando. L'utilizzo dei cookies è condizionato dal browser che usi per navigare. La maggior parte dei browser accetta automaticamente i cookies ma in qualunque momento puoi scegliere di farne a meno modificando le preferenze del browser. Questo potrebbe però comportare alcune limitazioni nell'utilizzo del nostro sito.</p>

            <strong>Quali sono i dati che salvate nei vostri cookies?</strong>
            <p>https://tommyvitello.it utilizza i cookies. Questi NON consentono l'accesso al tuo computer o ai tuoi dati e contengono esclusivamente le informazioni relative alla tua navigazione sul nostro sito. Nei cookies https://tommyvitello.it registra le seguenti informazioni: i tuoi dati di accesso (solo ed esclusivamente se richiesto da te durante il login o la registrazione), la tua nazione, la lingua con cui navighi sul nostro sito (italiano o inglese) e un codice identificativo che ti consente di ricordare i prodotti che hai eventualmente aggiunto al carrello (che rimane attivo per la durata di 30 giorni) e preservare i dati inseriti all'interno dei form.</p>

            <strong>Utilizzate altri cookies?</strong>

            <p>https://tommyvitello.it utilizza servizi e strumenti di terze parti che a loro volta si avvalgono dei cookies. https://tommyvitello.it  fa uso di Google Analytics, uno strumento molto diffuso che consente di analizzare le attività relative alla navigazione di un sito: numero di pagine visitate, numero di visitatori per pagina, lingua parlata dai visitatori, tempo trascorso su una determinata pagina, etc. Le informazioni raccolte dai cookies di Google Analytics vengono salvate in forma anonima, trasmesse e depositate presso i server di Google Inc. negli Stati Uniti allo scopo di compilare report sul traffico e le attività di un sito ad uso degli operatori del sito stesso.  https://tommyvitello.it utilizza inoltre strumenti messi a disposizione dai più diffusi social network (Facebook, Instagram,  e Pinterest), i quali a loro volta si avvalgono di cookies, allo scopo di consentire la condivisione dei contenuti presenti sul nostro sito agli utenti che ne fanno uso. Per ulteriori delucidazioni puoi controllare le policy dei singoli social network.</p>

            <h3>TITOLARE DEL TRATTAMENTO</h3>
            <p>Il titolare del trattamento dei dati personali è la società  Pantarei sas di Verdicchio Moreno & C. , P. IVA 03437630126 , Sede legale Viale Luigi Pirandello, 4 Busto Arsizio (VA) -  tel. 331 9717958 - 347 4279893 mail tommyvitello.2015@gmail.com</p>

            <h3>DIRITTI DEGLI INTERESSATI</h3>
            <p>
              Gli utenti/visitatori possono esercitare i propri diritti con riferimento ai dati trattati dal Titolare contattando il Titolari ai recapiti sopra riportati.
              In particolare hanno il diritto di:
              revocare il consenso in ogni momento;
              opporsi al trattamento dei propri dati, quando esso avviene su una base giuridica diversa dal consenso;
              accedere ai propri dati, ottenendo informazioni su di essi e/o richiedendone copia;
              verificare la correttezza dei propri dati e richiederne l’aggiornamento o la correzione;
              ottenere la limitazione del trattamento (quando ricorrono determinate condizioni);
              ottenere la cancellazione o la rimozione dei dati personali;
              proporre reclamo all’Autorità di controllo (Garante per la protezione dei dati personali) o autorità giudiziaria.
            </p>
          </div>
					</div>
				</div>



				<div className='footr'>
          <Footer />
				</div>
			</div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification
)(HomePage);
